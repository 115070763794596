export const REALM_LIST = 'REALM_LIST';
export const REALM_CREATION = 'REALM_CREATION';
export const REALM_UPDATION = 'REALM_UPDATION';
export const REALM_DELETION = 'REALM_DELETION';
export const REALM_RESEND_ACTIVATION_EMAIL = 'REALM_RESEND_ACTIVATION_EMAIL';

export const PROFILE_UPDATE = 'PROFILE_UPDATE';
export const PROFILE_PASSWORD = 'PROFILE_PASSWORD';

// users
export const USER_LIST = 'USER_LIST';
export const USER_CREATION = 'USER_CREATION';
export const USER_UPDATION = 'USER_UPDATION';
export const USER_DELETION = 'USER_DELETION';
export const PROJECT_CREATION_SAVE_INFO = 'PROJECT_CREATION_SAVE_INFO';
export const USER_RESEND_ACTIVATION_EMAIL = 'USER_RESEND_ACTIVATION_EMAIL';

export const ROLE_LIST = 'ROLE_LIST';
export const ROLE_CREATION = 'ROLE_CREATION';
export const ROLE_UPDATION = 'ROLE_UPDATION';
export const ROLE_DELETION = 'ROLE_DELETION';

export const USER_ACTIVATION = 'USER_ACTIVATION';

export const ROLE_PERMISSION_UPDATION = 'ROLE_PERMISSION_UPDATION';

// projects data

export const PROJECT_LIST = 'PROJECT_LIST';
export const PROJECT_CREATION = 'PROJECT_CREATION';
export const PROJECT_UPDATION = 'PROJECT_UPDATION';
export const PROJECT_DELETION = 'PROJECT_DELETION';
export const PROJECT_METHODOLOGIES = 'PROJECT_METHODOLOGIES';
export const PROJECT_CLAIMED = 'PROJECT_CLAIMED';
export const PROJECT_CREDIT_ACCEPTED = 'PROJECT_CREDIT_ACCEPTED';

export const REPORTING_VIEW = 'REPORTING_VIEW';

export const POST_LIST = 'POST_LIST';
export const POST_CREATE = 'POST_CREATE';
export const POST_UPDATE = 'POST_UPDATE';

export const REQUEST_LIST = 'REQUEST_LIST';
export const REQUEST_CREATE = 'REQUEST_CREATE';
export const REQUEST_ACCEPT = 'REQUEST_ACCEPT';
export const REQUEST_REJECT = 'REQUEST_REJECT';

export const PENDING_TRANSFER_LIST = 'PENDING_TRANSFER_LIST';
export const PENDING_TRANSFER_ACCEPT = 'PENDING_TRANSFER_ACCEPT';
export const PENDING_TRANSFER_REJECT = 'PENDING_TRANSFER_REJECT';
export const PENDING_TRANSFER_FINALIZE = 'PENDING_TRANSFER_FINALIZE';
export const PENDING_TRANSFER_CANCEL = 'PENDING_TRANSFER_CANCEL';

export const NOTIFICATIN_SETTINGS = 'NOTIFICATIN_SETTINGS';
